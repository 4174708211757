import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { interpolateString } from "../../helpers/Strings"
import { Routes } from "../../data/routes"
import { FamilyFAQs } from "../../data/faq"
import { Plans as PlansData, PlanType } from "../../data/plans"
import {
  SecondaryButton,
  SecondaryOutlineButton,
} from "../../components/button"
import Card from "../../components/card"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline"
import { Disclosure } from "@headlessui/react"
import { SimpleCTA } from "../../components/CTA"

const Content = {
  pageName: "Plans",
  subheader: "Invest in your child's future",
  header: "Unlimited math tutoring is now as low as ${{price}} a month",
  description: `Join Yup today for 24/7 access to expert math tutoring!
    The full cost for all plans is billed up-front. The monthly breakdown
    is featured for comparison.`,

  // Error info
  errorHeader: `We're sorry, we are unable to load plans at this time`,
  errorAltPrompt: "Please contact us for more information",
  errorAltActions: [{ link: Routes.contact, text: "Contact Us" }],

  // Demo
  demoTitle: "Accelerate student learning. Expand teacher capacity.",
  demoText: "Are you a teacher or school leader? Want to see it in action?",
  demoButton: "Schedule a demo",
  demoLink: Routes.schools,

  // Tier info
  mostPopular: "Most popular",
  noTrialText: "No Trial",
  select: "Select Plan",

  // FAQ section
  faqHeader: "Frequently Asked Questions",
  faqs: FamilyFAQs,
}

function PricingSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/pricing#component-f3dd88b8abfd735d38873b8daa09d07d
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [plansList, setPlansList] = useState<Array<PlanType>>()

  useEffect(() => {
    ;(async () => {
      try {
        const result = await PlansData.get()
        setPlansList(result)
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    })()
  }, [])

  function getLowestPrice() {
    return Math.floor(
      Math.min(...(plansList ?? []).map(plan => plan.monthlyPrice))
    )
  }

  if (loading) return null
  else if (error)
    return (
      <SimpleCTA
        title={Content.errorHeader}
        title2={Content.errorAltPrompt}
        actions={Content.errorAltActions}
      />
    )

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h3 className="text-secondary tracking-wider uppercase font-display mb-4">
        {Content.subheader}
      </h3>
      <h1 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
        {interpolateString(Content.header, { price: getLowestPrice() })}
      </h1>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">
        {Content.description}
      </p>

      {/* Tiers */}
      <div className="mt-16 lg:grid lg:grid-cols-2 items-end">
        {plansList?.map(tier => (
          <Card
            key={`${tier.title} ${tier.months}`}
            className="relative h-full w-full flex flex-col"
          >
            <div className="flex-1 mb-4">
              <h3 className="text-xl font-semibold text-gray-900">
                {tier.title}
              </h3>
              {tier.popular && (
                <p className="absolute top-0 py-1.5 px-4 bg-secondary-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  {Content.mostPopular}
                </p>
              )}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">
                  ${tier.price.toLocaleString()}
                </span>
              </p>
              {!tier.popular && (
                <p className="mt-6 text-gray-500">{Content.noTrialText}</p>
              )}
              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features?.map(feature => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="flex-shrink-0 w-6 h-6 text-secondary"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            {tier.popular ? (
              <SecondaryButton
                href={`${Routes.purchase}?sku=${tier.sku}`}
                text={Content.select}
              />
            ) : (
              <SecondaryOutlineButton
                href={`${Routes.purchase}?sku=${tier.sku}`}
                text={Content.select}
              />
            )}
          </Card>
        ))}
      </div>

      {/* Demo option */}
      <Card>
        <div className="text-center">
          <h3 className="text-3xl font-semibold text-gray-900">
            {Content.demoTitle}
          </h3>
          <h3 className="mt-4 text-xl text-gray-700">{Content.demoText}</h3>
          <div className="mt-4">
            <SecondaryButton
              text={Content.demoButton}
              href={Content.demoLink}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

function FAQSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1
  function FAQLink(props: { action: string; link: string }) {
    if (props.link.startsWith("http") || props.link.startsWith("mailto")) {
      return (
        <a className="text-blue" href={props.link} target="_blank">
          {props.action}.
        </a>
      )
    }
    return (
      <Link to={props.link} className="text-blue">
        {props.action}.
      </Link>
    )
  }

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {Content.faqHeader}
        </h2>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {Content.faqs.map(faq => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={`h-6 w-6 transform ${
                            open ? "-rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">
                      {faq.answer}&nbsp;
                      {faq.action && (
                        <FAQLink action={faq.action} link={faq.link} />
                      )}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
}

const Plans = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.plans}
      />
      <PricingSection />
      <FAQSection />
    </Layout>
  )
}

export default Plans
